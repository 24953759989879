import React from "react"
import {TimelineItemEntry, TimelineItemEntryPosition} from "../../utils/types"
import {GatsbyImage} from "gatsby-plugin-image"
import {getLineStyling} from "../../utils/timelineUtils"

const TimelineMemberItem: React.FC<TimelineItemEntry> = (item: TimelineItemEntry) => {
  const getImageStyling = (alignment: TimelineItemEntryPosition) => {
    const corners =
      alignment === TimelineItemEntryPosition.Left
        ? "md:rounded-r-lg md:rounded-l-none"
        : "md:rounded-l-lg md:rounded-r-none"
    return `rounded-t-lg ${corners}`
  }

  const getMemberBlockStyling = (alignment: TimelineItemEntryPosition) => {
    const flexClasses =
      alignment === TimelineItemEntryPosition.Left ? "flex-col-reverse md:flex-row-reverse" : "flex-col md:flex-row"
    const style = `order-1 bg-white z-10 border-tercen-blue border-2 rounded-lg w-5/12 flex overflow-hidden ${flexClasses}`
    return style
  }

  return (
    <>
      <div className={getLineStyling(item.position, "tercen-blue")}></div>
      <div className={getMemberBlockStyling(item.position)}>
        <div className="flex-auto hidden md:block" style={{maxWidth: "120px"}}>
          <GatsbyImage
            alt={`${item.title} image`}
            style={{maxWidth: "120px"}}
            image={item.avatar.localFile.childImageSharp.gatsbyImageData}
          />
        </div>
        {item.position === TimelineItemEntryPosition.Right && (
          <div className="flex-auto md:hidden">
            <GatsbyImage
              alt={`${item.title} image`}
              style={{maxWidth: "300px"}}
              image={item.avatar.localFile.childImageSharp.gatsbyImageData}
            />
          </div>
        )}
        <div className="flex-1 flex flex-col justify-center text-center md:text-left py-3 md:py-0">
          <h3 className="text-lg font-semibold text-tercen-blue px-3">+ {item.title}</h3>
          <p className="text-sm font-source-sans-pro px-3 pt-1">{item.tagline}</p>
        </div>
        {item.position === TimelineItemEntryPosition.Left && (
          <div className="flex-auto md:hidden">
            <GatsbyImage
              alt={`${item.title} image`}
              style={{maxWidth: "300px"}}
              image={item.avatar.localFile.childImageSharp.gatsbyImageData}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default TimelineMemberItem
