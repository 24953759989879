export interface SharpImage {
  publicURL: string
  childImageSharp: {
    gatsbyImageData: any
  }
}

export interface StrapiSharpImage {
  localFile: SharpImage
  mime: string
}

export interface StrapiMixedMedia {
  mime: string
  localFile: {
    publicURL: string
    extension: string
    url: string
    childImageSharp?: {
      gatsbyImageData: any
    }
  }
}

export interface CTA {
  URL: string
  text: string
  class?: string
  id?: string
}

export enum TimelineItemEntryType {
  Milestone = "milestone",
  Member = "member",
  Event = "event",
}

export enum TimelineItemEntryPosition {
  Left = "left",
  Right = "right",
}

export interface TimelineItemEntry {
  title: string
  subtitle: string
  tagline: string
  type: TimelineItemEntryType
  order: number
  avatar: StrapiSharpImage
  position: TimelineItemEntryPosition
}
