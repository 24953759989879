import React from "react"
import {TimelineItemEntry} from "../../utils/types"
import TimelineMemberItem from "./timelineMemberItem"

const TimelineDoubleMemberItem: React.FC<Array<TimelineItemEntry>> = (items: Array<TimelineItemEntry>) => {
  return (
    <div className="mb-8 flex justify-between items-center w-full">
      <TimelineMemberItem {...items[0]} />
      <div className="z-20 flex items-center order-1 bg-tercen-blue hover:bg-tercen-yellow shadow-xl w-6 h-6"></div>
      <TimelineMemberItem {...items[1]} />
    </div>
  )
}

export default TimelineDoubleMemberItem
