import React from "react"
import {getLineStyling} from "../../utils/timelineUtils"
import {TimelineItemEntry, TimelineItemEntryPosition} from "../../utils/types"

const TimelineMilestoneItem: React.FC<TimelineItemEntry> = (item: TimelineItemEntry) => {
  return (
    <>
      <div className={getLineStyling(item.position, "gray-700")}></div>
      <div className={`order-1 z-10 w-5/12 ${item.position === TimelineItemEntryPosition.Left ? "text-right" : ""}`}>
        <h3 className="text-xl font-semibold bg-tercen-yellow rounded-lg inline-block px-6 py-2 text-black">
          {item.title}
        </h3>
      </div>
    </>
  )
}

export default TimelineMilestoneItem
