import React from "react"
import {TimelineItemEntry, TimelineItemEntryType} from "../../utils/types"

const TimelineItemMarker: React.FC<TimelineItemEntry> = (item: TimelineItemEntry) => {
  const getMarkerStyling = (type: TimelineItemEntryType) => {
    let styling = "z-20 flex items-center order-1 shadow-xl "
    switch (type) {
      case TimelineItemEntryType.Milestone:
        styling += "bg-tercen-yellow hover:bg-tercen-blue w-6 h-6"
        break
      case TimelineItemEntryType.Event:
        styling += "bg-gray-700 hover:bg-tercen-yellow w-2 h-2"
        break
      case TimelineItemEntryType.Member:
        styling += "bg-tercen-blue hover:bg-tercen-yellow w-6 h-6"
        break
    }
    return styling
  }
  return (
    <>
      <div className="order-1 w-5/12"></div>
      <div className={getMarkerStyling(item.type)}></div>
    </>
  )
}

export default TimelineItemMarker
