import React from "react"
import {TimelineItemEntry, TimelineItemEntryPosition} from "../../utils/types"

const TimelineEventItem: React.FC<TimelineItemEntry> = (item: TimelineItemEntry) => {
  return (
    <div
      className={`order-1 bg-white z-10 w-5/12 ${
        item.position === TimelineItemEntryPosition.Left ? "text-right" : ""
      }`}>
      <h3 className="font-semibold text-gray-700">{item.title}</h3>
    </div>
  )
}

export default TimelineEventItem
