import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
import {TimelineItemEntry} from "../utils/types"
import TimelineItem from "../components/timeline/timelineItem"
import TimelineDoubleMemberItem from "../components/timeline/timelineDoubleMemberItem"

interface Props {
  readonly data: PageQueryData
}

const Team: React.FC<Props> = ({data}) => {
  const {title} = data.site.siteMetadata
  const founders: Array<TimelineItemEntry> = [
    data.items.edges[data.items.edges.length - 1].node,
    data.items.edges[data.items.edges.length - 2].node,
  ]
  return (
    <Layout title={title}>
      <Head title="Meet the team" keywords={[`data analysis`, `data analytics`, `data science`, `tercen`]} />
      <section className="full-w">
        <div className="container pt-16 pb-6 px-4 text-center">
          <h1 className="font-bold text-black text-5xl md:text-6xl leading-tight">
            Collaboration is
            <br />
            at our core!
          </h1>
          <p className="text-xl md:text-2xl text-tercen-orange-base font-source-sans-pro">
            Learn, share and analyze together.
          </p>
          <div className="flex justify-center py-6">
            <a
              href={data.site.siteMetadata.signUpLink}
              target="_blank"
              className="text-sm bg-tercen-green-base transition duration-500 ease-in-out hover:bg-tercen-green-hover text-white py-2 px-6 rounded-sm font-medium"
              rel="noreferrer">
              Sign up free
            </a>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center mb-20">
        <div className="container mx-auto w-full">
          <h1 className="font-bold text-black text-lg md:text-2xl leading-tight text-center">
            Meet the team and see our timeline
          </h1>
          <div className="relative wrap overflow-hidden p-4 lg:w-10/12 mx-auto">
            <div className="timeline-line"></div>

            <div className="mb-8 flex justify-center w-full">
              <div className="bg-gray-700 timeline-cube h-4"></div>
            </div>
            {data.items.edges.map(({node}, idx: number) => {
              if (idx < data.items.edges.length - 2) {
                return <TimelineItem {...node} key={idx} />
              }
            })}
            <TimelineDoubleMemberItem {...founders} />
          </div>
          <p className="text-center text-xl font-semibold text-black py-3">2017</p>
        </div>
      </section>
    </Layout>
  )
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
      signUpLink: string
    }
  }
  items: {
    edges: {
      node: TimelineItemEntry
    }[]
  }
}

export const pageQuery = graphql`
  query TeamQuery {
    site {
      siteMetadata {
        title
        signUpLink
      }
    }
    items: allStrapiTimelineItems(sort: {fields: order, order: DESC}) {
      edges {
        node {
          order
          title
          subtitle
          tagline
          type
          position
          avatar {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(height: 300, quality: 80)
              }
            }
          }
        }
      }
    }
  }
`
export default Team
