import React from "react"
import {TimelineItemEntry, TimelineItemEntryPosition, TimelineItemEntryType} from "../../utils/types"
import TimelineEventItem from "./timelineEventItem"
import TimelineItemMarker from "./timelineItemMarker"
import TimelineMemberItem from "./timelineMemberItem"
import TimelineMilestoneItem from "./timelineMilestoneItem"

const TimelineItem: React.FC<TimelineItemEntry> = (item: TimelineItemEntry) => {
  const getItemComponent = (item: TimelineItemEntry) => {
    switch (item.type) {
      case TimelineItemEntryType.Milestone:
        return <TimelineMilestoneItem {...item} />
      case TimelineItemEntryType.Event:
        return <TimelineEventItem {...item} />
      case TimelineItemEntryType.Member:
        return <TimelineMemberItem {...item} />
    }
  }

  const getItemClassname = (item: TimelineItemEntry) => {
    let klassNames = "mb-8 flex justify-between items-center w-full"
    switch (item.position) {
      case TimelineItemEntryPosition.Left:
        klassNames += " flex-row-reverse"
      case TimelineItemEntryPosition.Right:
        klassNames += " flex-row"
    }
    return klassNames
  }

  return (
    <div className={getItemClassname(item)}>
      <TimelineItemMarker {...item} />
      {getItemComponent(item)}
    </div>
  )
}

export default TimelineItem
